@use '../../styles/colors';
@use '../../styles/fonts';
@use '../../styles/mixins';

.root {
  margin: 0;
  position: relative;
}

.button {
  background: colors.$tw-red-600;
  border-radius: 200px;
  border-style: none;
  color: colors.$white;
  cursor: pointer;
  display: block;
  font: 900 20px/45px var(--font-brand);
  height: 45px;
  outline: none;
  padding: 0 30px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transform: translate(0, 0px);
  transition: all 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
  white-space: nowrap;
  z-index: 2;

  @include mixins.bp-sm {
    font: 900 20px/55px var(--font-brand);
    height: 55px;
    padding: 0 60px;
  }
}

.button:hover {
  background-color: colors.$tw-red-700;
  text-decoration: none;
  transform: translate(0, 3px);
}

.background {
  background: colors.$tw-red-800;
  border-radius: 200px;
  height: 100%;
  left: 2px;
  position: absolute;
  right: 2px;
  top: 5px;
  z-index: 1;
}
