@use './colors';
@use './fonts';
@use './sizes';

@mixin bp-sm {
  @media (min-width: sizes.$sm) {
    @content;
  }
}

@mixin bp-md {
  @media (min-width: sizes.$md) {
    @content;
  }
}

@mixin bp-lg {
  @media (min-width: sizes.$lg) {
    @content;
  }
}

@mixin bp-xl {
  @media (min-width: sizes.$xl) {
    @content;
  }
}

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin reset-button {
  background: transparent;
  border-style: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

@mixin body-text {
  color: colors.$tw-gray-900;
  font-weight: 400;
  font-size: fonts.$size-16;
  line-height: 1.8;
  font-family: var(--font-brand);

  &.text--inverted,
  &.text--white {
    color: colors.$white;
  }

  p {
    margin: 0;
  }

  p:global(.spacer) {
    line-height: 14px;
    margin: 0;
  }
}
