$black: #000;
$white: #ffffff;

$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$blue-50-a25: rgba(#e3f2fd, 0.25);
$blue-50-a50: #f3f9fe; //rgba(#e3f2fd, 0.5);
$blue-50: #e3f2fd;
$blue-100: #bbdefb;
$blue-200: #90caf9;
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;
$blue-A100: #82b1ff;
$blue-A200: #448aff;
$blue-A400: #2979ff;
$blue-A700: #2962ff;

$light-blue-50: #e1f5fe;
$light-blue-100: #b3e5fc;
$light-blue-200: #81d4fa;
$light-blue-300: #4fc3f7;
$light-blue-400: #29b6f6;
$light-blue-500: #03a9f4;
$light-blue-600: #039be5;
$light-blue-700: #0288d1;
$light-blue-800: #0277bd;
$light-blue-900: #01579b;

$cyan-50: #e0f7fa;
$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;

$teal-50: #e0f2f1;
$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;

$red-50: #ffebee;
$red-100: #ffcdd2;
$red-200: #ef9a9a;
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;
$red-A100: #ff8a80;
$red-A200: #ff5252;
$red-A400: #ff1744;
$red-A700: #d50000;

$pink-50: #fce4ec;
$pink-100: #f8bbd0;
$pink-200: #f48fb1;
$pink-300: #f06292;
$pink-400: #ec407a;
$pink-500: #e91e63;
$pink-600: #d81b60;
$pink-700: #c2185b;
$pink-800: #ad1457;
$pink-900: #880e4f;

$purple-50: #f3e5f5;
$purple-100: #e1bee7;
$purple-200: #ce93d8;
$purple-300: #ba68c8;
$purple-400: #ab47bc;
$purple-500: #9c27b0;
$purple-600: #8e24aa;
$purple-700: #7b1fa2;
$purple-800: #6a1b9a;
$purple-900: #4a148c;

$deep-purple-50: #ede7f6;
$deep-purple-100: #d1c4e9;
$deep-purple-200: #b39ddb;
$deep-purple-300: #9575cd;
$deep-purple-400: #7e57c2;
$deep-purple-500: #673ab7;
$deep-purple-600: #5e35b1;
$deep-purple-700: #512da8;
$deep-purple-800: #4527a0;
$deep-purple-900: #311b92;

$indigo-50: #e8eaf6;
$indigo-100: #c5cae9;
$indigo-200: #9fa8da;
$indigo-300: #7986cb;
$indigo-400: #5c6bc0;
$indigo-500: #3f51b5;
$indigo-600: #3949ab;
$indigo-700: #303f9f;
$indigo-800: #283593;
$indigo-900: #1a237e;

$yellow-50: #fffde7;
$yellow-100: #fff9c4;
$yellow-200: #fff59d;
$yellow-300: #fff176;
$yellow-400: #ffee58;
$yellow-500: #ffeb3b;
$yellow-600: #fdd835;
$yellow-700: #fbc02d;
$yellow-800: #f9a825;
$yellow-900: #f57f17;
$yellow-A100: #ffff8d;
$yellow-A200: #ffff00;
$yellow-A400: #ffea00;
$yellow-A700: #ffd600;

$light-green-50: #f1f8e9;
$light-green-100: #dcedc8;
$light-green-200: #c5e1a5;
$light-green-300: #aed581;
$light-green-400: #9ccc65;
$light-green-500: #8bc34a;
$light-green-600: #7cb342;
$light-green-700: #689f38;
$light-green-800: #558b2f;
$light-green-900: #33691e;
$light-green-A100: #ccff90;
$light-green-A200: #b2ff59;
$light-green-A400: #76ff03;
$light-green-A700: #64dd17;

$green-50: #e8f5e9;
$green-100: #c8e6c9;
$green-200: #a5d6a7;
$green-300: #81c784;
$green-400: #66bb6a;
$green-500: #4caf50;
$green-600: #43a047;
$green-700: #388e3c;
$green-800: #2e7d32;
$green-900: #1b5e20;
$green-A100: #b9f6ca;
$green-A200: #69f0ae;
$green-A400: #00e676;
$green-A700: #00c853;

$lime-50: #f9fbe7;
$lime-100: #f0f4c3;
$lime-200: #e6ee9c;
$lime-300: #dce775;
$lime-400: #d4e157;
$lime-500: #cddc39;
$lime-600: #c0ca33;
$lime-700: #afb42b;
$lime-800: #9e9d24;
$lime-900: #827717;

$blue-grey-50: #eceff1;
$blue-grey-100: #cfd8dc;
$blue-grey-200: #b0bec5;
$blue-grey-300: #90a4ae;
$blue-grey-400: #78909c;
$blue-grey-500: #607d8b;
$blue-grey-600: #546e7a;
$blue-grey-700: #455a64;
$blue-grey-800: #37474f;
$blue-grey-900: #263238;

$amber-50: #fff8e1;
$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;
$amber-A100: #ffe57f;
$amber-A200: #ffd740;
$amber-A400: #ffc400;
$amber-A700: #ffab00;

$orange-50: #fff3e0;
$orange-100: #ffe0b2;
$orange-200: #ffcc80;
$orange-300: #ffb74d;
$orange-400: #ffa726;
$orange-500: #ff9800;
$orange-600: #fb8c00;
$orange-700: #f57c00;
$orange-800: #ef6c00;
$orange-900: #e65100;
$orange-A100: #ffd180;
$orange-A200: #ffab40;
$orange-A400: #ff9100;
$orange-A700: #ff6d00;

$deep-orange-50: #fbe9e7;
$deep-orange-100: #ffccbc;
$deep-orange-200: #ffab91;
$deep-orange-300: #ff8a65;
$deep-orange-400: #ff7043;
$deep-orange-500: #ff5722;
$deep-orange-600: #f4511e;
$deep-orange-700: #e64a19;
$deep-orange-800: #d84315;
$deep-orange-900: #bf360c;

$brown-50: #efebe9;
$brown-100: #d7ccc8;
$brown-200: #bcaaa4;
$brown-300: #a1887f;
$brown-400: #8d6e63;
$brown-500: #795548;
$brown-600: #6d4c41;
$brown-700: #5d4037;
$brown-800: #4e342e;
$brown-900: #3e2723;

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$gradient-yellow: linear-gradient(50deg, #ffe686, #ffcc71);
$gradient-orange: linear-gradient(80deg, #f46b45, #eea849);

$hero-red-500: #f25f5c;

$hero-green-100: #d0f2e4;
$hero-green-300: #30c285;
$hero-green-500: #2db57d;
$hero-green-700: #1d7551;
$hero-green-900: #0d3625;

$hero-purple-100: #dbd7ef;
$hero-purple-300: #9d95c4;
$hero-purple-500: #8774dc;
$hero-purple-700: #5e548e;
$hero-purple-900: #201942;

// Tail wind v1.9.0 inspired colors
$tw-gray-100: #f7fafc;
$tw-gray-200: #edf2f7;
$tw-gray-300: #e2e8f0;
$tw-gray-400: #cbd5e0;
$tw-gray-500: #a0aec0;
$tw-gray-600: #718096;
$tw-gray-700: #4a5568;
$tw-gray-800: #2d3748;
$tw-gray-900: #1a202c;

$tw-red-100: #fff5f5;
$tw-red-200: #fed7d7;
$tw-red-300: #feb2b2;
$tw-red-400: #fc8181;
$tw-red-500: #f56565;
$tw-red-600: #e53e3e;
$tw-red-700: #c53030;
$tw-red-800: #9b2c2c;
$tw-red-900: #742a2a;

$tw-orange-100: #fffaf0;
$tw-orange-200: #feebc8;
$tw-orange-300: #fbd38d;
$tw-orange-400: #f6ad55;
$tw-orange-500: #ed8936;
$tw-orange-600: #dd6b20;
$tw-orange-700: #c05621;
$tw-orange-800: #9c4221;
$tw-orange-900: #7b341e;

$tw-yellow-100: #fffff0;
$tw-yellow-200: #fefcbf;
$tw-yellow-300: #faf089;
$tw-yellow-400: #f6e05e;
$tw-yellow-500: #ecc94b;
$tw-yellow-600: #d69e2e;
$tw-yellow-700: #b7791f;
$tw-yellow-800: #975a16;
$tw-yellow-900: #744210;

$tw-green-100: #f0fff4;
$tw-green-200: #c6f6d5;
$tw-green-300: #9ae6b4;
$tw-green-400: #68d391;
$tw-green-500: #48bb78;
$tw-green-600: #38a169;
$tw-green-700: #2f855a;
$tw-green-800: #276749;
$tw-green-900: #22543d;

$tw-blue-100: #ebf8ff;
$tw-blue-200: #bee3f8;
$tw-blue-300: #90cdf4;
$tw-blue-400: #63b3ed;
$tw-blue-500: #4299e1;
$tw-blue-600: #3182ce;
$tw-blue-700: #2b6cb0;
$tw-blue-800: #2c5282;
$tw-blue-900: #2a4365;

$tw-purple-50: #faf5ff;
$tw-purple-100: #f3e8ff;
$tw-purple-200: #e9d5ff;
$tw-purple-300: #d8b4fe;
$tw-purple-400: #c084fc;
$tw-purple-500: #a855f7;
$tw-purple-600: #9333ea;
$tw-purple-700: #7e22ce;
$tw-purple-800: #6b21a8;
$tw-purple-900: #581c87;

// landing
$bittersweet: #ff7070;
$fuzzy-wuzzy-brown: #c8544f;
$mine-shaft: #3a3a3a;
$peach: #ffe3b4;
$sandy-beach: #ffe9c3;
$lily-white: #e9f9ff;
$ronchi: #f0c452;
$picton-blue: #52d4f0;
$heliotrope: #e352f0;
$cornflower-blue: #6cabef;
$carnation: #f05252;
$color-sunset-orange: #f64747; // Acc on white bg
$lavender-blush: #ffe9ed;
$blue-marguerite: #7568c7;
$starship: #f0e052;
$turquoise-blue: #52e7f0;
$turquoise-blue-2: #52f0d4;
$inchworm: #98f052;
$medium-purple: #a254ef;
