.root {
  color: var(--slate-900);
  font-family: var(--font-brand);
  font-size: var(--font-size-3xl);
  font-weight: 900;
  line-height: var(--line-height-snug);
  margin: 0;
}

@media (min-width: 1024px) {
  .root {
    font-size: var(--font-size-4xl);
    line-height: var(--line-height-tight);
  }
}