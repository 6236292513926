@use '../styles/colors';
@use '../styles/fonts';
@use '../styles/mixins';
@use '../styles/sizes';

.root {
  margin: 0 auto;
  max-width: var(--container-width-md);
  padding: 120px 12px;
  position: relative;
}

.root p,
.root li {
  color: var(--slate-600);
  font-family: var(--font-brand);
  font-size: var(--font-size-base);
  font-weight: normal;
  line-height: var(--line-height-relaxed);
  margin: var(--space-6) 0 0 0;
}

.root blockquote {
  background: var(--slate-50);
  border-left: var(--space-1) solid var(--slate-500);
  margin: var(--space-7) 0 0;
  padding: var(--space-6);
}

.root blockquote p {
  margin: 0;
}

.root blockquote em {
  display: block;
}

.root img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}

.image {
  aspect-ratio: 1/0.5;
  width: 100%;
  margin: 24px 0 0 0;

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
  }
}

// about page
.member {
  margin: 120px 0 0 0;
  display: flex;
  gap: 12px;

  @include mixins.bp-md {
    gap: 30px;
  }
}

.memberImageWrap {
  flex: 0 0 90px;
  padding: 0 0 0 0;
  position: relative;

  @include mixins.bp-md {
    flex: 0 0 160px;
  }
}

.memberImageWrap::after {
  content: '';
  width: 90px;
  height: 90px;
  z-index: -1;
  border-radius: 50%;
  display: block;
  position: absolute;

  @include mixins.bp-md {
    width: 160px;
    height: 160px;
  }
}

.memberImage {
  border-radius: 50%;
  overflow: hidden;
  background-size: contain;
  background-position: 50%;
  width: 90px;
  height: 90px;

  @include mixins.bp-md {
    width: 160px;
    height: 160px;
  }
}

.member:nth-child(1) {
  margin: 30px 0 0 0;

  .memberImage {
    border: 3px solid #52d4f0;
  }

  .memberImageWrap::after {
    background: #52d4f0;
    top: 3px;
    left: 3px;

    @include mixins.bp-md {
      top: 6px;
      left: 6px;
    }
  }
}
.member:nth-child(2) {
  .memberImage {
    border: 3px solid #98f052;
  }

  .memberImageWrap::after {
    background: #98f052;
    top: 3px;
    left: -3px;

    @include mixins.bp-md {
      top: 6px;
      left: -6px;
    }
  }
}
.member:nth-child(3) {
  .memberImage {
    border: 3px solid #e352f0;
  }

  .memberImageWrap::after {
    background: #e352f0;
    top: 3px;
    left: -3px;

    @include mixins.bp-md {
      top: 6px;
      left: -6px;
    }
  }
}
.member:nth-child(4) {
  .memberImage {
    border: 3px solid #7568c7;
  }

  .memberImageWrap::after {
    background: #7568c7;
    top: -3px;
    left: -3px;

    @include mixins.bp-md {
      top: -6px;
      left: -6px;
    }
  }
}
.member:nth-child(5) {
  .memberImage {
    border: 3px solid #f0e052;
  }

  .memberImageWrap::after {
    background: #f0e052;
    top: -3px;
    left: 3px;

    @include mixins.bp-md {
      top: -6px;
      left: 6px;
    }
  }
}
